<template>
    <div class="pt-2 ml-2">           
        <div v-if="!isChannelActive" class="mt-2 mr-2 ml-2">
            <CBadge color="danger" class="float-right mt-1">Channel inactive</CBadge>    
            <span class="font-italic text-danger">The channel "{{channel.label}}" is not active or not configured</span>
        </div>        
        
        <div v-if="!isConfigured" class="mt-2 mr-2 ml-2">
            <CBadge color="warning" class="float-right mt-1">Not configured</CBadge>    
            <span class="font-italic">The product is not configured for the channel "{{channel.label}}"</span>
        </div> 
               
        <template v-else>

            <div class="form-group form-row mt-1 mb-0">
                <div class="col-sm-1 label-col">
                    <label class="col-form-label strong" >Active</label>
                </div>
                <div class="col-sm-1 d-flex">
                    <CSwitch size="sm" color="success" variant="3d" :checked="isProductActive" :disabled="disabled" style="align-self: center !important" />
                </div>
                <!-- <edit-combo class="mr-2 mt-2" style="transform: translateY(-1px)" /> -->
                <div class="col-sm-4" :class="{'opacity-1': config.prio == null}">
                    <label class="col-form-label strong" >Priority:</label>&nbsp;
                    <span class="col-form-label" >{{config.prio != null ? config.prio : "-"}}</span>
                </div>                
            </div>

            <div class="form-group form-row mt-1 mb-0 " v-if="config.productId" >
                <div class="col-sm-1 label-col">
                    <label class="col-form-label strong" >{{config.productId.label}}</label>
                </div>
                <span class="col-sm-5 form-control">{{ config.productId.value }}</span>
            </div>

            <div class="form-group form-row mt-3 mb-0" :class="{'opacity-1': !isProductActive}">               
                <label class="col-form-label col-sm-1 col-lg-1 strong label-col">Review Link</label>
                 <div class="col-sm-4 d-flex"> 
                     <CSwitch size="sm" color="success" variant="3d" :checked.sync="isReviewActive" :disabled="!isProductActive || disabled"  style="align-self: center !important" /> 
                </div>
            </div>

            <div class="form-group form-row mt-1 mb-0" :class="{'opacity-1': !isProductActive || !isReviewActive}">               
                <label class="col-form-label col-sm-1 col-lg-1 strong label-col">Review URL</label>
                <div v-if="config.inheritsReviewUrl" class="col d-flex" style="display: flex; flex-direction: row; align-items: center; padding-left: 0px;">      
                    <div style="width: 100%;">                                  
                        <span class="form-control" :class="{'text-secondary': !isReviewActive, 'text-info': isReviewActive }">{{ config.reviewUrl }}</span>   
                        <div class="d-flex flex-row align-items-center">                                                
                        <CIcon name="cilDataTransferDown" size="sm" class="mr-1" />
                        <small class="form-text text-muted w-100">The URL is configured in the <router-link class="strong text-info" :to="`/channels/${channel.name}`">channel settings</router-link></small>  
                        </div>
                    </div>                    
                </div>  
                <span v-if="!config.inheritsReviewUrl" class="col form-control strong" :class="{'text-primary': isReviewActive, 'text-secondary': !isReviewActive}">{{ config.reviewUrl }}</span>
            </div>
            
            <div class="form-group form-row mt-1 mb-0" :class="{'opacity-1': !isProductActive || !isReviewActive}">
                <div class="col-md-1 label-col" >
                    <label class="col-form-label strong" >Review Cap</label>
                </div>
                <div class="col-md-1 label-col" :class="{'opacity-1': !config.maxDailyReviews}">
                    <label class="col-form-label font-weight-500" >Daily:</label>
                    &nbsp;{{ config.maxDailyReviews ? config.maxDailyReviews : "-" }}
                </div>
                <div class="col-md-1 label-col" :class="{'opacity-1': !config.maxWeeklyReviews}">
                    <label class="col-form-label font-weight-500" >Weekly:</label>
                    &nbsp;{{ config.maxWeeklyReviews ? config.maxWeeklyReviews : "-" }}
                </div>                                    
            </div>           

             <div class="form-group form-row mt-1 mb-0" :class="{'opacity-1': !isProductActive || !isReviewActive}">
                <div class="col-sm-1 label-col">
                    <label class="col-form-label strong">Min. Rating</label>
                </div>
                 <div class="col d-flex ml-0 pl-0" :title="config.minRating ? `Min. ${config.minRating} stars` : 'Any'">
                    <Rating :rating="config.minRating" style="align-self: center !important;" class="pb-1" />
                </div>   
            </div>             
        </template>    
    </div>                            
</template>
<script>
import Rating from '@/components/Rating';
//import EditCombo from './EditCombo.vue';

export default {
    props: {
        channel: Object,
        disabled: Boolean
    },
    components: {
        Rating,
        //EditCombo
    },
    data() {
        return {
            isProductActive: false,
            isReviewActive: false,
            isChannelActive: false
        }
    },
    computed: {
        config() {
            return this.channel.config;
        },
        isConfigured() {
            return this.channel.isConfigured && this.channel.config;
        }
    },
    methods: {
        init() {
            if (this.config) {
                this.isProductActive = this.config.isProductActive;
                this.isReviewActive = this.config.isReviewActive;
            }
            this.isChannelActive = this.channel ? this.channel.isActive : false
        }
    },
    created() {
        this.init();
    },
    watch: {
        channel() {
            this.init();
        },
        isProductActive() {
            if (this.config) {
                var o = this;
                setTimeout(function() {
                    o.isProductActive = o.config.isProductActive;
                }, 20);
            }
        },
        isReviewActive() {
            if (this.config) {
                var o = this;
                setTimeout(function() {
                    o.isReviewActive = o.config.isReviewActive;
                }, 20);
            }
        }
    }
}
</script>

<style scoped>
.text-black {
    color: black;
}
.label-col {
    min-width: 120px;
}
</style>
