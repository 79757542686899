var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{staticClass:"mt-2 mb-0 pb-0",attrs:{"add-table-classes":"mb-0","items":_vm.rewards,"items-per-page":10,"pagination":"","header":false,"hover":"","__style":"cursor: pointer","fields":[
    { key:'kind', _style: 'width: 105px', label: ''},
    { key:'imageUrl', label: ''},
    { key:'sku', _style: 'width:150px' }, 
    { key:'title', _classes: 'h6', label: 'Title / Description' }, 
    { key:'isAvailable', label:'Available', _style: 'width: 60px;'},
    { key: 'remove', _style: 'width: 60px;' }
    ]},on:{"__row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"kind",fn:function(ref){
    var item = ref.item;
return [_c('td',{class:{'text-danger':item.isMissing, 'text-secondary': !item.isAvailable},staticStyle:{"width":"105px"}},[(item.kind == 'Bonus')?[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-gift","size":"lg"}}),_c('strong',[_vm._v("Bonus")])]:_vm._e(),(item.kind == 'Product')?[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-cart","size":"lg"}}),_c('strong',[_vm._v("Product")])]:_vm._e(),[_c('br'),(item.isMissing)?_c('CIcon',{staticClass:"mt-1 text-danger",attrs:{"name":"cil-warning","size":"lg"}}):_vm._e()]],2)]}},{key:"imageUrl",fn:function(ref){
    var item = ref.item;
return [_c('ProductsListImageColumn',{attrs:{"rowData":item,"disabled":!item.isAvailable}})]}},{key:"sku",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"width":"150px"}},[_c('CLink',{class:[item.isMissing ? 'text-danger' : 'text-info', 'strong'],attrs:{"to":("/bonuses/view/" + (item.sku))}},[_vm._v(_vm._s(item.sku))])],1)]}},{key:"title",fn:function(ref){
    var item = ref.item;
return [_c('td',{class:{'text-secondary': !item.isAvailable},staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.rowClicked(item)}}},[((!item.title))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Title missing")]):_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),((!item.description))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Description missing")]):_c('span',[_vm._v(_vm._s(item.description))])],1)]}},{key:"isAvailable",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"width":"85px"}},[_c('CBadge',{attrs:{"color":item.isAvailable ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.isAvailable ? "Available" : "Out of Stock")+" ")])],1)]}},{key:"remove",fn:function(ref){
    var item = ref.item;
return [(_vm.canEdit)?_c('td',{staticClass:"pl-0 pr-1",staticStyle:{"width":"80px"}},[_c('CButton',{staticClass:"py-0 px-2 text-nowrap",attrs:{"color":"warning"},on:{"click":function($event){return _vm.onRemove(item)}}},[_c('CIcon',{staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-trash","size":"sm"}}),_vm._v(" "),_c('small',[_vm._v("Remove")])],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }