<template>
    <CDataTable
        class="mt-2 mb-0 pb-0"
        add-table-classes="mb-0"
        :items="rewards"
        :items-per-page="10"
        pagination       
        :header="false"
        hover
        __style="cursor: pointer"
        @__row-clicked="rowClicked"
         :fields="[
        { key:'kind', _style: 'width: 105px', label: ''},
        { key:'imageUrl', label: ''},
        { key:'sku', _style: 'width:150px' }, 
        { key:'title', _classes: 'h6', label: 'Title / Description' }, 
        { key:'isAvailable', label:'Available', _style: 'width: 60px;'},
        { key: 'remove', _style: 'width: 60px;' }
        ]"
    >             
        <template #kind="{item}">
            <td style="width: 105px;" :class="{'text-danger':item.isMissing, 'text-secondary': !item.isAvailable}">                
                <template v-if="item.kind == 'Bonus'" ><CIcon name="cil-gift" size="lg" class="mr-2" /><strong>Bonus</strong></template>                
                <template v-if="item.kind == 'Product'"><CIcon name="cil-cart" size="lg" class="mr-2" /><strong>Product</strong></template>
                <template><br /><CIcon v-if="item.isMissing" name="cil-warning" size="lg" class="mt-1 text-danger"/></template>
            </td>
        </template>      
        <template #imageUrl="{item}" >
            <ProductsListImageColumn :rowData='item' :disabled="!item.isAvailable" />
        </template>    
        <template #sku="{item}">
            <td style="width: 150px">         
                <CLink :to="`/bonuses/view/${item.sku}`" :class="[item.isMissing ? 'text-danger' : 'text-info', 'strong']">{{item.sku}}</CLink>
            </td>
        </template>
        <template #title="{item}">
            <td :class="{'text-secondary': !item.isAvailable}" @click="rowClicked(item)" style="cursor: pointer">
                <CBadge v-if="(!item.title)" color="light">Title missing</CBadge><strong v-else>{{ item.title }}</strong><br />
                <CBadge v-if="(!item.description)" color="light">Description missing</CBadge><span v-else>{{ item.description }}</span>
            </td>
        </template>    
        <template #isAvailable="{item}">
            <td style="width: 85px">
                <CBadge :color="item.isAvailable ? 'success' : 'danger'">
                    {{ item.isAvailable ? "Available" : "Out of Stock" }}
                </CBadge>
            </td>
        </template>
        <template #remove="{item}">
            <td v-if="canEdit" style="width: 80px" class="pl-0 pr-1">
                <CButton color="warning" class="py-0 px-2 text-nowrap" @click="onRemove(item)">
                    <CIcon name="cil-trash" style="transform: translateY(-2px)" size="sm" />&nbsp;<small>Remove</small>
                </CButton>
            </td>
        </template>
    </CDataTable>
</template>
<script>
import ProductsListImageColumn from '@/components/ProductsListImageColumn';
import { EDIT_PRODUCT, mapPermissions } from '@/permissions';

export default {
    props: {
        rewards: Array
    },
    components: {
        ProductsListImageColumn
    },
    methods: {
        rowClicked(item) {
            this.$router.push(`/bonuses/view/${item.sku}`);
        },
        onRemove(item) {
            this.$emit("remove", item);
        }
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_PRODUCT
        })
    }
}
</script>
