<template>
<CCard style="min-height: 120px;"> 
    <CCardHeader class="pt-2 pb-0">          
        <div class="float-right mt-1 pt-0 mb-1">
            <CSwitch ref="activeSwitch" size="sm" color="success" variant="3d" :disabled="isLoading || !product || !canEdit" :checked.sync="isProductActive"    
                    @update:checked="onIsActiveChange"
            ></CSwitch>  
        </div>
        <em v-if="(product && !isProductActive)" class="float-right mt-1 mr-3">Enable product here</em>                            
        <div class="float-left d-flex flex-row mt-1 mb-1" style="width:auto">
            <CIcon name="cil-cart" size="lg" class="mr-2" /><h5 class="">Product Details: {{ sku }}</h5>     
        </div>                         
        <CBadge v-if="(product && !product.isActive)" color="light" class="ml-2 mt-2 mb-2">Disabled</CBadge>            
        <CSpinner v-if='isLoading' class="ml-2 mt-2" size="sm" color="primary" />        
    </CCardHeader>
    <CCardBody class="pt-3 pb-3">            
        <CAlert v-if="!isLoading && !product" color="danger" class="mt-0 mb-0">
            Failed to load product. <CLink class="strong" to="/products">Click here</CLink> to return to products list. 
        </CAlert>  
        <template v-if="!!product">
            <div class="d-flex">
                <ImageUploader :sku="sku" :merchantId="merchantId" :imageFile="productImageUrl" />
                <div class="ml-3 d-flex flex-column">
                    <div class="mb-1">
                        <strong>{{ product.title}}</strong>
                    </div>
                    <CBadge v-if="(!product.description)" color="light">Description missing</CBadge><span v-else>{{ product.description }}</span>
                    <div class="mt-2 pt-2" style="justify-self: end; border-top: solid 1px #ddd">
                        <div v-for="identifier in product.identifiers" :key="identifier.value" class="d-flex justify-content-start">
                            <label class="strong mb-0 p-0" style="width: 60px;">{{identifier.label}}</label>
                            <span>{{identifier.value}}</span>
                        </div>
                    </div>
                </div>                
            </div>
        </template>        
    </CCardBody>

    <CCardHeader v-if="!!product && hasTranslations" class="pt-2 pb-2 top-border" @click="expandTranslations">     
        <CIcon v-if="isTranslationExpanded" name="cilChevronTop" class="float-right" size="sm" />
        <CIcon v-else name="cilChevronBottom" class="float-right" size="sm" />
        <h6 class="m-0 mt-1" style="cursor: pointer">Translations</h6>            
    </CCardHeader>        
    <CCollapse v-if="!!product && hasTranslations" :show="isTranslationExpanded">
        <CCardBody class="pt-0">
            <EditCombo v-if="canEdit"
                :edit="editTranslations"
                @edit="onEditTranslations"
                @cancel="onCancelEditTranslations"
                @save="onSaveTranslations"
                :class="['float-right','pt-2', editTranslations?'':'mt-3']"
                style="margin-left: -400px; transform: translateY(1px)"
            />
            <CTabs v-if="hasTranslations && !editTranslations" :activeTab.sync="activeTranslationTab">                
                <CTab v-for="tl in product.translations" :key="tl.language" addTabsClasses="info" class="pt-3">
                    <template slot="title">
                        <span :class="{'text-warning': tl.isMissing}">{{ tl.language }}</span>
                    </template>
                    <template v-if="!tl.isMissing">                    
                        <div class="pl-2">
                            <div><strong>{{ tl.title }}</strong></div>
                            <div><i v-if="(!tl.description)" class="text-secondary">Description missing</i><span v-else>{{ tl.description }}</span></div>
                        </div>                        
                    </template>
                    <template v-else>
                        <div class="pl-2">
                            <div class="opacity-1"><strong>{{ product.title }}</strong></div>
                            <div class="opacity-1"><i v-if="(!tl.description)">Description missing</i><span v-else>{{ tl.description }}</span></div>                        
                            <CBadge v-if="!product.isProduct" color="warning" class="py-1 px-2 mt-2" @click="onEditTranslations" style="cursor: pointer">Add translation</CBadge>
                        </div>
                    </template>
                </CTab>
            </CTabs>
            <TranslationEditor v-if="hasTranslations && editTranslations" 
                ref="tlEditor" mode="product"
                :translations="product.translations" 
                :title="product.title"
                :description="product.description"
            />
        </CCardBody>
    </CCollapse>        

    <CCardHeader v-if="!!product && canViewChannel" class="pt-2 pb-2" @click="expandChannels" :class="{'top-border': isTranslationExpanded}">     
        <CIcon v-if="isChannelsExpanded" name="cilChevronTop" class="float-right" size="sm" />
        <CIcon v-else name="cilChevronBottom" class="float-right" size="sm" />
        <h6 class="m-0 mt-1" style="cursor: pointer">Channel Settings</h6>            
    </CCardHeader>  
    <CCollapse v-if="!!product && canViewChannel" :show="isChannelsExpanded">
        <!-- <CSwitch class="mt-2 mb-0 mr-3 float-right" /> -->
        <CCardBody class="pt-2 pb-3">
            <CTabs :activeTab.sync="activeChannelTab">
                <CTab v-for="channel in product.channels" :key="channel.name" class="mt-0 pt-0 pb-0">
                    <template slot="title">
                        <span :class="{'text-warning': !channel.isConfigured, 'text-danger': !channel.isActive || (channel.isConfigured && !channel.config.isProductActive) }">{{channel.label}}</span>
                    </template>
                    <ProductChannelDetails :channel="channel" :disabled="isLoading || (product && !product.isActive) || !canEdit" />
                </CTab>      
            </CTabs> 
        </CCardBody>
    </CCollapse>

    <CCardHeader v-if="!!product && canViewReward" class="pt-2 pb-2" @click="expandRewards"  :class="{'top-border': isChannelsExpanded}">      
        <CIcon v-if="isRewardsExpanded" name="cilChevronTop" class="float-right" size="sm" />
        <CIcon v-else name="cilChevronBottom" class="float-right" size="sm" />       
        <h6 style="cursor: pointer" :class="{'m-0 mt-1':true, 'text-danger':hasRewardIssues}">Rewards</h6>          
    </CCardHeader>
    <CCollapse v-if="!!product && canViewReward" :show="isRewardsExpanded">
        <CCardBody v-if="product" class="pt-1 pb-3">
            <RewardsTable v-if="product.rewards && (product.rewards.length > 0)" :rewards="product.rewards" @remove="onRemoveItem" class="mb-0" />
            <CAlert v-if="product.rewards && (product.rewards.length == 0)" color="danger" class="mt-2 mb-1 py-1 px-2">No reward configured! Click the <em>'Add Reward'</em> button to add bonus SKU as reward</CAlert>
            <div v-if="canEdit && leftBonusSkus && leftBonusSkus.length > 0" class="d-flex mt-2">
                <CSelect horizontal 
                         class="mr-1 mt-0 no-wrap my-0 form-group form-row filter-group px-0" 
                         addLabelClasses="strong text-nowrap date-label-col mx-0 col-form-label col-sm-3 px-0"                    
                         add-input-classes="mx-0 px-0"
                         add-wrapper-classes="col-sm-12"
                        :options="leftBonusSkus"
                        :value="addRewardSku"
                        @update:value="onAddRewardSkuUpdate"
                         style="width: 300px;"
                />
                <CButton color="primary" 
                    @click="onAddRewardClick"
                    :disabled="isLoading"
                ><CIcon name="cilPlus" class="mt-0 mb-1" /> Add Reward</CButton>
            </div>
        </CCardBody>
    </CCollapse>   
    <ConfirmActivation ref="confirmActiveModal" 
        :activatFnc="setActiveFnc" 
        :title="(isProductActive ? 'Activate' : 'Deactivate') + ' product?'"
        :message="boxMessage"
        :loadingMessage="(isProductActive ? 'Activating' : 'Deactivating') + ' product...'"
        @cancel="onCancelIsActive" 
    /> 
    <ConfirmActivation 
         ref="removeRewardModal"
         title="Remove Reward"
        :message="`Remove the reward SKU '${removeRewardSKU}' for this product?`"
         loadingMessage="Removing reward SKU"
        :activatFnc="removeReward" 
    />    
</CCard>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';
import ImageUploader from '@/components/ImageUploader.vue';
import ProductChannelDetails from '@/components/ProductChannelDetails.vue';
import RewardsTable from '@/components/RewardsTable.vue';
import ConfirmActivation from '@/components/modals/ConfirmActivation';
import EditCombo from '@/components/EditCombo';
import TranslationEditor from '@/components/TranslationEditor';
import { EDIT_PRODUCT, VIEW_CHANNEL, VIEW_REWARD, mapPermissions } from '@/permissions';

export default {
    props: {
        sku: String,
        rewards: String,
        channel: String,
        translation: String
    },
    components: {
        ImageUploader,
        ProductChannelDetails,
        RewardsTable,
        ConfirmActivation,
        EditCombo,
        TranslationEditor
    },
    data() { 
        return {
            isTranslationExpanded: false,
            isRewardsExpanded: false,
            isChannelsExpanded: false,
            activeTranslationTab: 0,
            activeChannelTab: 0,
            isProductActive: false,
            setActiveFnc: null,
            editTranslations: false,
            removeRewardSKU: null,
            addRewardSku: null
        }
    },
    methods: {
        ...mapActions({
            loadProduct: actionNames.LOAD_PRODUCT, 
            setActive: actionNames.SET_PRODUCT_ACTIVE,
            updateProductInternal: actionNames.UPDATE_PRODUCT
        }),    
        async updateProduct(payload) {
            await this.updateProductInternal(payload);
            this.onProductLoaded();
        }, 
        async initProduct() {
            await this.loadProduct({sku:this.sku});  
            this.onProductLoaded();

            this.imageMissing = false;            

            this.isTranslationExpanded = false;
            this.isChannelsExpanded = false;
            this.isRewardsExpanded = false;

            if (this.translation) {                
                const tl = this.product.translations.find(o => o.language == this.translation);
                if (tl) {
                    this.activeTranslationTab = this.product.translations.indexOf(tl);                                        
                }
                this.isTranslationExpanded = true;
            }
            else if (this.rewards) {
                this.isRewardsExpanded = true;
            }
            else if (this.channel) {            
                const channel = this.product.channels.find(ch => ch.name == this.channel);
                this.activeChannelTab = channel ? this.product.channels.indexOf(channel) : 0;
                this.isChannelsExpanded = true;
            }
            else {
                this.isChannelsExpanded = true;
            }  

            this.isProductActive = this.product ? this.product.isActive : false;
        },
        onProductLoaded() {
            if (this.leftBonusSkus && this.leftBonusSkus.length > 0) {
                this.addRewardSku = this.leftBonusSkus[0];
            } else {
                this.addRewardSku = null;
            }
        },        
        expandTranslations()
        {
            this.isTranslationExpanded = this.isTranslationExpanded ? false : true
            if (this.isTranslationExpanded) {
                this.isChannelsExpanded = this.isRewardsExpanded = false;            
            }            
        },
        expandRewards() {
            this.isRewardsExpanded = this.isRewardsExpanded ? false : true;
            if (this.isRewardsExpanded) {
                this.isChannelsExpanded = this.isTranslationExpanded = false;
                this.$router.replace(`/products/view/${this.sku}?rewards=true`);
            }
        },
        expandChannels() {
            this.isChannelsExpanded = this.isChannelsExpanded ? false : true;
            if (this.isChannelsExpanded) {
                this.isRewardsExpanded = this.isTranslationExpanded = false;
            }
        },
        // Click handlers:
        /* eslint-disable */
        onIsActiveChange(active) {
            const o = this;        
            this.setActiveFnc = async () => {
                await o.setActive({channel:null,isActive:active});
            };
            const modal = o.$refs.confirmActiveModal;
            modal.show();
        },
        onCancelIsActive() {
            const o = this;       
            setTimeout(function() {
                o.isProductActive = o.product.isActive;
            }, 100);
        },
        onEditTranslations() {            
            this.editTranslations = true;

            const o = this;
            const activeTab = this.activeTranslationTab;

            setTimeout(function() {
                o.$refs.tlEditor.activeTab = activeTab;
            });
        },
        onCancelEditTranslations() {
            const activeTab = this.$refs.tlEditor.activeTab;  
            this.activeTranslationTab = activeTab;
            this.editTranslations = false;
        },
        async onSaveTranslations() {
            await this.$refs.tlEditor.save();
            const activeTab = this.$refs.tlEditor.activeTab;            
            this.editTranslations = false;
            this.activeTranslationTab = activeTab;
        },
        onRemoveItem(item) {
            this.removeRewardSKU = item.sku;
            const modal = this.$refs.removeRewardModal;
            modal.show();
        },
        async removeReward() {
            await this.updateProduct({sku:this.sku, removeRewardSKU: this.removeRewardSKU});
        },
        onAddRewardSkuUpdate(value) {
            this.addRewardSku = value;
        },
        async onAddRewardClick() {
            if (this.addRewardSku) {
                await this.updateProduct({sku:this.sku, addRewardSku: this.addRewardSku});
            }
        }
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_PRODUCT,
            canViewChannel: VIEW_CHANNEL,
            canViewReward: VIEW_REWARD
        }),
        hasTranslations() {
            return this.product && this.product.translations && this.product.translations.length > 0;
        },        
        productImageUrl() {
            return this.product && this.product.imageFile ? this.product.imageFile: null
        },
        ...mapGetters({
            merchantId: 'merchantId',
            product: 'products/product',
            isLoading: 'products/loading',
            bonusSkus: 'products/bonusSkus',
            hasRight: 'hasRight'
        }),
        boxMessage() {
            return `Do you want to ${(this.isProductActive ? "":"de")}activate the product${(this.isProductActive?"":" for all channels")}?`;
        },
        hasRewardIssues() {
            return this.product && 
                  (this.product.rewards.length == 0 || this.product.rewards.every(p => p.isMissing || !p.isAvailable));
        },
        leftBonusSkus() {
            return this.bonusSkus.filter(b => !this.product.rewards.some(r =>  r.sku == b));
        }
    },
    created() {
        this.initProduct();
    },
    watch: {
        merchantId() {
            this.initProduct();
        },
        sku() {
            this.initProduct();
        },
        rewards() {
            this.initProduct();
        },
        channel() {
            this.initProduct();
        },        
        translation() {
            this.initProduct();
        }
    }
}
</script>
<style scoped>
    a.nav-link .text-black {
        color: black;
    }
    .text-black {
        color: black;
    }
    header.top-border {
        border-top-style: solid;
        border-top-width: 1px;
    }
</style>
